import React, { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	FormControl,
	InputLabel,
	Input,
	Button,
	makeStyles,
	Grid,
	Container,
	Box,
} from '@material-ui/core';
import { useHistory, Redirect } from 'react-router-dom';
import { useFirebase } from '../components/Firebase/useFirebase';
import { CLAIMS } from '../util/Routes';
import log from 'loglevel';
import { useSession } from '../components/auth/Session';
import logo from '../images/logo-madsky.png';

const useStyles = makeStyles((theme) => ({
	// container: {
	// 	marginTop:
	// }
}));

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null,
};

function LoginPage() {
	const classes = useStyles();
	const [state, setState] = useState(INITIAL_STATE);

	const [isInvalid, setIsInvalid] = useState(false);
	useEffect(() => {
		setIsInvalid(!state.email || !state.password);
	}, [state]);

	const firebase = useFirebase();
	const history = useHistory();

	const { isAuthenticated } = useSession();
	if (isAuthenticated) {
		return <Redirect to={CLAIMS} />;
	}

	const onSubmit = (e) => {
		e.preventDefault();
		const { email, password } = state;

		firebase
			.doLoginWithEmailAndPassword(email, password)
			.then(() => {
				log.info('successful login');
				setState({ ...INITIAL_STATE });
				history.push(CLAIMS);
			})
			.catch((error) => {
				setState({
					...state,
					error,
				});
			});
	};

	const onChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	return (
		<Container>
			<Box mt={4}>
				<Grid container justify="center">
					<Grid item xs={12} md={4} lg={3}>
						<Card>
							<CardContent>
								<Grid container justify="center">
									<Grid item xs={10}>
										<img src={logo} alt="Kozi" width="100%" height="auto" />
									</Grid>
								</Grid>

								<form
									className={classes.root}
									noValidate
									autoComplete="off"
									onSubmit={onSubmit}
								>
									<Grid item>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="email">Email</InputLabel>
											<Input
												id="email"
												aria-describedby="email-text"
												placeholder="Email"
												type="email"
												name="email"
												value={state.email}
												onChange={onChange}
												autoComplete="email"
											/>
											{/* <FormHelperText id="email-text">We'll never share your email.</FormHelperText> */}
										</FormControl>
									</Grid>
									<Grid item>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="password">Password</InputLabel>
											<Input
												id="password"
												placeholder="Password"
												type="password"
												name="password"
												value={state.password}
												onChange={onChange}
												autoComplete="current-password"
											/>
										</FormControl>
									</Grid>
									<Grid item>
										<FormControl fullWidth={true} margin="normal">
											<Button type="submit" disabled={isInvalid}>
												Login
											</Button>
											{state.error && <p>{state.error.message}</p>}
										</FormControl>
									</Grid>
								</form>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}

export default LoginPage;
