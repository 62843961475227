import React, { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	makeStyles,
	Grid,
	Container,
	Box,
	CircularProgress,
	// Button,
	// List,
	// ListItem,
	// ListItemIcon,
	// ListItemText,
	// Chip,
	// Link as muiLink,
	Typography,
} from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import InboxIcon from '@material-ui/icons/Inbox';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Claim from '../../models/Claim';
// import { LOGOUT } from '../../util/Routes';

const useStyles = makeStyles((theme) => ({
	chip: {
		marginRight: '10px',
	},
	title: {
		textAlign: 'center',
	},
}));

// const ListItemLink = (props) => {
// 	return <ListItem button component={Link} {...props} />;
// };

const CardPage = (props) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [fetched, setFetched] = useState(false);

	const [claim, setClaim] = useState(null);
	const id = props.match.params.id;

	useEffect(() => {
		if (fetched) return;

		const req = async () => {
			const c = await Claim.find(id);
			setClaim(c);
			setLoading(false);
		};

		req();
		setFetched(true);
	}, [fetched, id]);

	return (
		<Container>
			<Box mt={4}>
				<Grid container justify="center">
					<Grid item xs={12} md={8} lg={6}>
						<Card>
							<CardContent>
								{loading ? (
									<Box display="flex" justifyContent="center" mt={4}>
										<CircularProgress />
									</Box>
								) : (
									<Grid>
										<Grid item>
											<Typography variant="h4" gutterBottom className={classes.title}>
												Claim {claim.number}
											</Typography>
										</Grid>
										<Grid item container spacing={4} justify="center">
											<Grid item>
												<Typography variant="h6">
													{claim.customer_given_name +
														' ' +
														claim.customer_family_name}
												</Typography>
												<Typography variant="caption">Customer Name</Typography>
											</Grid>
											<Grid item>
												<Typography variant="h6"> {claim.customer_phone} </Typography>
												<Typography variant="caption">Customer Phone</Typography>
											</Grid>
											<Grid item>
												<Typography variant="h6"> {claim.customer_email} </Typography>
												<Typography variant="caption">Customer Email</Typography>
											</Grid>
											<Grid item>
												<Typography variant="h6"> {claim.loss_location} </Typography>
												<Typography variant="caption">Loss Location</Typography>
											</Grid>
											<Grid item>
												<Typography variant="h6"> {claim.check_in} </Typography>
												<Typography variant="caption">Check In</Typography>
											</Grid>
											<Grid item>
												<Typography variant="h6"> {claim.check_out} </Typography>
												<Typography variant="caption">Check Out</Typography>
											</Grid>
										</Grid>
									</Grid>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};

export default CardPage;
