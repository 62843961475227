import cc from 'clipboard-copy';
import React, { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	makeStyles,
	Grid,
	Container,
	Box,
	CircularProgress,
	// Button,
	// List,
	// ListItem,
	// ListItemIcon,
	// ListItemText,
	Chip,
	Link as muiLink,
	Typography,
	Snackbar,
} from '@material-ui/core';
import QS from 'qs';
import { Link } from 'react-router-dom';
// import InboxIcon from '@material-ui/icons/Inbox';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MuiAlert from '@material-ui/lab/Alert';
import logo from '../../images/logo-madsky.png';
import Claim from '../../models/Claim';
import { LOGOUT } from '../../util/Routes';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const convertDate = (date) => {
	let parts = date.split('-');

	return `${parts[1]}/${parts[2]}/${parts[0]}`;
};

const useStyles = makeStyles((theme) => ({
	chip: {
		marginRight: '10px',
	},
	title: {
		textAlign: 'center',
	},
}));

// const ListItemLink = (props) => {
// 	return <ListItem button component={Link} {...props} />;
// };

const CardPage = (props) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [fetched, setFetched] = useState(false);
	const [open, setOpen] = useState(false);
	const [staySource, setStaySource] = useState(
		'https://www.stay22.com/embed/gm?address=1%20Infinite%20Loop%2C%20Cupertino%2C%20CA%2095014'
	);

	const [claim, setClaim] = useState(null);
	const id = props.match.params.id;

	useEffect(() => {
		if (fetched) return;

		const req = async () => {
			const c = await Claim.find(id);
			setClaim(c);

			var s22obj = {
				maincolor: '92929B', // your main brand color in hex (without the #)
				// aid:       'affiliateid',                    // 1) your affiliate id for tracking
				// address: input.lossLocation, // 2) Lat/Lng or full street address. For latlng use the "lat":,"lng": keys instead
				address: c.loss_location,
				checkin: convertDate(c.check_in),
				checkout: convertDate(c.check_out),
				showhotels: true,
				showairbnbs: true,
				showothers: false,
			};

			// https://www.stay22.com/embed/kozi?&showhotels=true&showairbnbs=true&showothers=false

			setStaySource('https://www.stay22.com/embed/gm?' + QS.stringify(s22obj));

			setLoading(false);
		};

		req();
		setFetched(true);
	}, [fetched, id]);

	const copy = (e) => {
		let which = '';

		switch (e.target.name) {
			case 'num':
				which = '4444 5555 6666 7777';
				break;
			case 'cvc':
				which = '111';
				break;
			case 'exp':
				which = '02/2022';
				break;
			default:
				break;
		}

		cc(which);
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<Container>
			<Box mt={4}>
				<Grid container justify="center">
					<Grid item xs={12} md={8} lg={6}>
						<Card>
							<CardContent>
								<Grid container justify="center">
									<Grid item>
										<img src={logo} alt="Kozi" width="100" height="auto" />
									</Grid>
								</Grid>

								{loading ? (
									<Box display="flex" justifyContent="center" mt={4}>
										<CircularProgress />
									</Box>
								) : (
									<Grid>
										<Grid item>
											<Typography variant="h4" gutterBottom className={classes.title}>
												Claim {claim.number}
											</Typography>
										</Grid>
										<Grid item>
											Your Kozi Card
											<Box>
												<Chip
													label="4444 5555 6666 7777"
													className={classes.chip}
													onClick={copy}
													name="num"
												/>
												<Chip
													label="111"
													className={classes.chip}
													onClick={copy}
													name="cvc"
												/>
												<Chip label="02/2022" onClick={copy} name="exp" />
											</Box>
										</Grid>
										<Grid item className>
											<Box mt={2}>
												<Typography variant="body2">
													Use this credit card information for your online housing
													payment. Copy this card information and paste it into the
													payment field once you’ve found your place to stay.
												</Typography>
											</Box>
											<Box mt={2}>
												<iframe
													title="stay"
													id="stay22-widget"
													width="100%"
													height="428"
													src={staySource}
													frameborder="0"
												></iframe>
											</Box>
										</Grid>
									</Grid>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
			<Box mt={2}>
				<Grid container justify="center">
					<Grid item>
						<Link
							component={muiLink}
							to={{
								pathname: LOGOUT,
							}}
						>
							Logout
						</Link>
					</Grid>
				</Grid>
			</Box>
			<Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					Copied!
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default CardPage;
