import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, AppBar, Toolbar, Container, IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { useSession } from './auth/Session';
import * as ROUTES from '../util/Routes';
import logo from '../images/logo-madsky.png';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		flexGrow: 1,
	},
	claimsButton: {
		marginRight: '10px',
	},
	link: {
		color: 'inherit',
		textDecoration: 'none',
	},
}));

export default function Bar() {
	const { isAuthenticated } = useSession();
	const location = useLocation();
	const classes = useStyles();

	if (location.pathname === ROUTES.LOGIN) {
		return null;
	}

	if (
		location.pathname.indexOf('/claimant') === 0 ||
		(location.pathname.indexOf('/payment') === 0 && location.pathname.indexOf('/payments') !== 0)
	) {
		return null;
	}

	return (
		<AppBar position="static" color="inherit">
			<Container>
				<Toolbar>
					<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
						<MenuIcon />
					</IconButton>
					{/* <Typography variant="h6" className={classes.title}>
						Kozi
					</Typography> */}
					<Box className={classes.title}>
						<img src={logo} alt="logo" height="50" width="auto" />
					</Box>
					{!isAuthenticated && (
						<Link
							className={classes.link}
							to={{
								pathname: ROUTES.LOGIN,
							}}
						>
							<Button color="inherit">Login</Button>
						</Link>
					)}
					{isAuthenticated && (
						<Link
							className={classes.link}
							to={{
								pathname: ROUTES.PAYMENTS,
							}}
						>
							<Button color="inherit" className={classes.claimsButton}>
								Payments
							</Button>
						</Link>
					)}
					{isAuthenticated && (
						<Link
							className={classes.link}
							to={{
								pathname: ROUTES.CLAIMS,
							}}
						>
							<Button color="inherit" className={classes.claimsButton}>
								Claims
							</Button>
						</Link>
					)}
					{isAuthenticated && (
						<Link
							className={classes.link}
							to={{
								pathname: ROUTES.LOGOUT,
							}}
						>
							<Button color="inherit">Logout</Button>
						</Link>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
}
