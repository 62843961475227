import { useHistory } from 'react-router-dom';
import { useFirebase } from '../components/Firebase/useFirebase';
import { LOGIN } from '../util/Routes';

function LogoutPage() {
	const firebase = useFirebase();
	const history = useHistory();

	firebase.doLogout().then(() => {
		history.push(LOGIN);
	});

	return null;
}

export default LogoutPage;
