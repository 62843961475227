import React, { useState } from 'react';
import {
	Box,
	Grid,
	Card,
	CardContent,
	makeStyles,
	Button,
	Paper,
	Table,
	TableHead,
	TableBody,
	withStyles,
	TableCell,
	TableRow,
	TableContainer,
	Typography,
	Chip,
	// InputLabel,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { PAYMENTS_SEND } from '../util/Routes';

const StyledTableCell = withStyles((theme) => ({
	head: {
		// backgroundColor: theme.palette.common.black,
		// color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	filter: {
		marginRight: '15px',
	},

	card: {
		marginBottom: '15px',
	},
});

export default function ClaimsPage() {
	const classes = useStyles();
	const [rows] = useState([
		{ id: 1, name: 'Sally Smith', amount: '$1,000', status: 'pending' },
		{ id: 2, name: 'John Doe', amount: '$250', status: 'pending' },
		{ id: 3, name: 'Simon Whistler', amount: '$1,500', status: 'paid-out' },
		{ id: 4, name: 'Rebecca Johnson', amount: '$550', status: 'processing' },
		{ id: 5, name: 'Joanne Carr', amount: '$5,200', status: 'processing' },
		{ id: 6, name: 'Dave Beck', amount: '$750', status: 'paid-out' },
	]);

	return (
		<Box mt={2}>
			<Grid container justify="center">
				<Grid item xs={12} md={8} lg={5}>
					<Card className={classes.card}>
						<CardContent>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="h5">Payments</Typography>
								<Box>
									<Link
										to={{
											pathname: PAYMENTS_SEND,
										}}
									>
										<Button variant="contained" color="primary">
											Send Payment
										</Button>
									</Link>
								</Box>
							</Box>
						</CardContent>
					</Card>

					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="customized table">
							<TableHead>
								<TableRow>
									<StyledTableCell>Recipient</StyledTableCell>
									<StyledTableCell align="right">Amount</StyledTableCell>
									<StyledTableCell align="right">Status</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => (
									<StyledTableRow key={row.id}>
										<StyledTableCell component="th" scope="row">
											{row.name}
										</StyledTableCell>
										<StyledTableCell align="right">{row.amount}</StyledTableCell>
										<StyledTableCell align="right">
											<Chip size="small" label={row.status} />
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Box>
	);
}
