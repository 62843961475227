// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app';

// Add the Firebase products that you want to use
import 'firebase/auth';
import { firebaseConfig } from './firebaseConfig';

export default class FirebaseClass {
	constructor() {
		firebase.initializeApp(firebaseConfig);

		this.auth = firebase.auth();
	}

	doLoginWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
	doSignInWithPhoneNumber = (phone, appVerifier) => this.auth.signInWithPhoneNumber(phone, appVerifier);

	doLogout = () => this.auth.signOut();

	doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
	doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

	RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
}
