import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { LOGIN } from './Routes';
import { useSession } from '../components/auth/Session';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useSession();

	return (
		<Route
			{...rest}
			render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to={LOGIN} />)}
		/>
	);
};
