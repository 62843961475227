import QS from 'qs';
import React, { useState, useEffect } from 'react';

import {
	Grid,
	Container,
	Divider,
	Button,
	Accordion,
	AccordionActions,
	AccordionSummary,
	AccordionDetails,
	Typography,
	Paper,
	FormControl,
	InputLabel,
	Input,
	Box,
	CircularProgress,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Claim from '../../models/Claim';
import { useHistory } from 'react-router-dom';

const convertDate = (date) => {
	let parts = date.split('-');

	return `${parts[1]}/${parts[2]}/${parts[0]}`;
};

var d = new Date(),
	month = '' + (d.getMonth() + 1),
	day = '' + d.getDate(),
	year = d.getFullYear();

if (month.length < 2) month = '0' + month;
if (day.length < 2) day = '0' + day;

const ds = [year, month, day].join('-');

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	paper: {
		padding: '16px',
		marginBottom: '16px',
	},
}));

export default function CreatePage() {
	const classes = useStyles();
	const history = useHistory();
	const [expanded, setExpanded] = useState(false);
	const [input, setInput] = useState({ checkIn: ds, checkOut: ds });
	const [loading, setLoading] = useState(false);

	const [staySource, setStaySource] = useState(
		'https://www.stay22.com/embed/gm?address=1%20Infinite%20Loop%2C%20Cupertino%2C%20CA%2095014'
	);

	// const [random, setRandom] = useState(0);

	useEffect(() => {
		var s22obj = {
			maincolor: '92929B', // your main brand color in hex (without the #)
			// aid:       'affiliateid',                    // 1) your affiliate id for tracking
			address: input.lossLocation, // 2) Lat/Lng or full street address. For latlng use the "lat":,"lng": keys instead
			// address: '4 Pennsylvania Plaza, New York, NY 10001',
			checkin: convertDate(input.checkIn),
			checkout: convertDate(input.checkOut),
			showhotels: true,
			showairbnbs: true,
			showothers: false,
		};

		// https://www.stay22.com/embed/kozi?&showhotels=true&showairbnbs=true&showothers=false

		setStaySource('https://www.stay22.com/embed/gm?' + QS.stringify(s22obj));
		// setStaySource(
		// 	'https://www.stay22.com/embed/gm?aid=affiliateid&lat=40.7505045&lng=-73.9934387&checkin=09/22/2020&checkout=09/24/2020'
		// );

		// setRandom(Math.random());
	}, [input]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const onChange = (e) => {
		const name = e.target.name;

		let i = { ...input };
		i[name] = e.target.value;

		setInput(i);
		console.log(i);
	};

	const onSubmit = async () => {
		setExpanded(null);
		setLoading(true);

		let claim = new Claim({
			number: input.claimNumber,
			loss_location: input.lossLocation,
			customer_given_name: input.givenName,
			customer_family_name: input.familyName,
			customer_email: input.email,
			customer_phone: input.phone,
			check_in: input.checkIn,
			check_out: input.checkOut,
			adult_count: input.adultCount,
			child_count: input.childCount,
			rooms_required: input.roomsRequired,
			extended_followup: input.extendedFollowup,
			ale_limit: input.aleLimit,
			ale_limit_daily: input.aleLimitDaily,
			allowance: input.allowance,
		});

		await claim.save();

		history.push(`/claims/${claim.id}/notify`);
	};

	// if (!Object.keys(input).length) {
	// 	setInput({
	// 		claimNumber: '123',
	// 		lossLocation: '123 walnut way',
	// 		givenName: 'Dan',
	// 		familyName: 'Squirrel',
	// 		email: 'cus@kozi.test',
	// 		phone: '1111111111',
	// 		checkIn: '2020-08-01',
	// 		checkOut: '2020-08-10',
	// 		adultCount: 1,
	// 		childCount: 0,
	// 		roomsRequired: 1,
	// 		extendedFollowup: 'n/a',
	// 		aleLimit: 50,
	// 		aleLimitDaily: 5,
	// 		allowance: 10,
	// 	});
	// }

	return (
		<Container>
			<Box mt={2}>
				<Grid container justify="center">
					<Grid item xs={12} md={8} lg={6}>
						<Paper className={classes.paper}>
							<Typography variant="h5">Create Claim</Typography>
						</Paper>

						{/* <code>{JSON.stringify(input)}</code> */}

						<Accordion
							expanded={expanded === 'panelCustomer'}
							onChange={handleChange('panelCustomer')}
							disabled={loading}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelCustomerA-content"
								id="panelCustomerA-header"
							>
								<Typography className={classes.heading}>Customer Information</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="claimNumber">Claim Number</InputLabel>
											<Input
												id="claimNumber"
												aria-describedby="claimNumber-text"
												placeholder="Claim Number"
												type="text"
												name="claimNumber"
												autoComplete="on"
												value={input.claimNumber}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="lossLocation">Loss Location</InputLabel>
											<Input
												id="lossLocation"
												aria-describedby="lossLocation-text"
												placeholder="Loss Location"
												type="text"
												name="lossLocation"
												autoComplete="on"
												value={input.lossLocation}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="givenName">Customer First Name</InputLabel>
											<Input
												id="givenName"
												aria-describedby="givenName-text"
												placeholder="Customer First Name"
												type="text"
												name="givenName"
												autoComplete="given-name"
												value={input.givenName}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="familyName">Customer Last Name</InputLabel>
											<Input
												id="familyName"
												aria-describedby="familyName-text"
												placeholder="Customer Last Name"
												type="text"
												name="familyName"
												autoComplete="family-name"
												value={input.familyName}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="email">Customer Email</InputLabel>
											<Input
												id="email"
												aria-describedby="email-text"
												placeholder="Customer Email"
												type="email"
												name="email"
												autoComplete="email"
												value={input.email}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="phone">Customer Phone</InputLabel>
											<Input
												id="phone"
												aria-describedby="phone-text"
												placeholder="Customer Phone Phone"
												type="text"
												name="phone"
												maxLength="10"
												minLength="10"
												autoComplete="phone"
												value={input.phone}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</AccordionDetails>
							<Divider />
							<AccordionActions>
								<Button size="small" color="primary" onClick={() => setExpanded('panelStay')}>
									Continue
								</Button>
							</AccordionActions>
						</Accordion>

						<Accordion
							expanded={expanded === 'panelStay'}
							onChange={handleChange('panelStay')}
							disabled={loading}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelStayA-content"
								id="panelStayA-header"
							>
								<Typography className={classes.heading}>Stay Information</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="checkIn">Check In</InputLabel>
											<Input
												id="checkIn"
												aria-describedby="checkIn-text"
												placeholder="Check In"
												type="date"
												name="checkIn"
												autoComplete="on"
												value={input.checkIn}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="checkOut">Check Out</InputLabel>
											<Input
												id="checkOut"
												aria-describedby="checkOut-text"
												placeholder="Check Out"
												type="date"
												name="checkOut"
												autoComplete="on"
												value={input.checkOut}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="adultCount">Number of Adults</InputLabel>
											<Input
												id="adultCount"
												aria-describedby="adultCount-text"
												placeholder="Number of Adults"
												type="text"
												name="adultCount"
												autoComplete="on"
												value={input.adultCount}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="childCount">Number of Children</InputLabel>
											<Input
												id="childCount"
												aria-describedby="childCount-text"
												placeholder="Number of Children"
												type="text"
												name="childCount"
												autoComplete="on"
												value={input.childCount}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="roomsRequired">Rooms Required</InputLabel>
											<Input
												id="roomsRequired"
												aria-describedby="roomsRequired-text"
												placeholder="Rooms Required"
												type="roomsRequired"
												name="roomsRequired"
												autoComplete="on"
												value={input.roomsRequired}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="extendedFollowup">
												Extended Followup
											</InputLabel>
											<Input
												id="extendedFollowup"
												aria-describedby="extendedFollowup-text"
												placeholder="Extended Followup"
												type="text"
												name="extendedFollowup"
												autoComplete="on"
												value={input.extendedFollowup}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</AccordionDetails>
							<Divider />
							<AccordionActions>
								<Button size="small" color="primary" onClick={() => setExpanded('panelAle')}>
									Continue
								</Button>
							</AccordionActions>
						</Accordion>

						<Accordion
							expanded={expanded === 'panelAle'}
							onChange={handleChange('panelAle')}
							disabled={loading}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panelAleA-content"
								id="panelAleA-header"
							>
								<Typography className={classes.heading}>Fair Value Rental Tool</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<iframe
											title="stay"
											// key={random}
											id="stay22-widget"
											width="100%"
											height="428"
											src={staySource}
											frameborder="0"
										></iframe>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="aleLimit">ALE Limit - Total</InputLabel>
											<Input
												id="aleLimit"
												aria-describedby="aleLimit-text"
												placeholder="ALE Limit - Total"
												type="text"
												name="aleLimit"
												autoComplete="on"
												value={input.aleLimit}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									{/* <Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="aleLimitDaily">ALE Limit - Daily</InputLabel>
											<Input
												id="aleLimitDaily"
												aria-describedby="aleLimitDaily-text"
												placeholder="ALE Limit - Daily"
												type="text"
												name="aleLimitDaily"
												autoComplete="on"
												value={input.aleLimitDaily}
												onChange={onChange}
											/>
										</FormControl>
									</Grid> */}
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="allowance">Card Allowance</InputLabel>
											<Input
												id="allowance"
												aria-describedby="allowance-text"
												placeholder="Card Allowance"
												type="text"
												name="allowance"
												autoComplete="on"
												value={input.allowance}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</AccordionDetails>
							<Divider />
							<AccordionActions>
								<Button size="small" color="primary" onClick={onSubmit}>
									Submit and Create
								</Button>
							</AccordionActions>
						</Accordion>
						{loading && <CircularProgress />}
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}
