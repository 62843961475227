import React, { useState } from 'react';

import {
	Grid,
	Container,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Input,
	Box,
	CircularProgress,
	Card,
	CardContent,
	CardActions,
	InputAdornment,
} from '@material-ui/core';

export default function SendPaymentPage() {
	const [input, setInput] = useState({});
	const [loading, setLoading] = useState(false);

	const onChange = (e) => {
		const name = e.target.name;

		let i = { ...input };
		i[name] = e.target.value;

		setInput(i);
		console.log(i);
	};

	const onSubmit = async () => {
		setLoading(true);
	};

	return (
		<Container>
			<Box mt={2}>
				<Grid container justify="center">
					<Grid item xs={12} md={8} lg={6}>
						<Card>
							<CardContent>
								<Typography variant="h5">Send Instant Payment</Typography>

								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="claimNumber">Claim Number</InputLabel>
											<Input
												id="claimNumber"
												aria-describedby="claimNumber-text"
												placeholder="Claim Number"
												type="text"
												name="claimNumber"
												autoComplete="on"
												value={input.claimNumber}
												onChange={onChange}
												startAdornment={
													<InputAdornment position="start">#</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="amount">Amount</InputLabel>
											<Input
												id="amount"
												aria-describedby="amount-text"
												placeholder="Amount"
												type="text"
												name="amount"
												autoComplete="on"
												value={input.amount}
												onChange={onChange}
												startAdornment={
													<InputAdornment position="start">$</InputAdornment>
												}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="email">Recipient Email</InputLabel>
											<Input
												id="email"
												aria-describedby="email-text"
												placeholder="Recipient Email"
												type="email"
												name="email"
												autoComplete="email"
												value={input.email}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="phone">Recipient Phone</InputLabel>
											<Input
												id="phone"
												aria-describedby="phone-text"
												placeholder="Recipient Phone Phone"
												type="text"
												name="phone"
												maxLength="10"
												minLength="10"
												autoComplete="phone"
												value={input.phone}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="givenName">Recipient First Name</InputLabel>
											<Input
												id="givenName"
												aria-describedby="givenName-text"
												placeholder="Recipient First Name"
												type="text"
												name="givenName"
												autoComplete="given-name"
												value={input.givenName}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="familyName">Recipient Last Name</InputLabel>
											<Input
												id="familyName"
												aria-describedby="familyName-text"
												placeholder="Recipient Last Name"
												type="text"
												name="familyName"
												autoComplete="family-name"
												value={input.familyName}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
								</Grid>
								{loading && <CircularProgress />}
							</CardContent>
							<CardActions>
								<Button size="small" color="primary" variant="contained" onClick={onSubmit}>
									Send
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}
