import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Grid, Snackbar, Card, CardContent, CircularProgress } from '@material-ui/core';
import { useFirebase } from '../components/Firebase';
import { validatePhone } from '../components/util/Phone';
import InputPhone from '../components/input/Phone';
import InputCode from '../components/verify-phone/InputCode';
import Alert from '@material-ui/lab/Alert';
// import { useHistory, Redirect } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useSession } from '../components/auth/Session';
import logo from '../images/logo-madsky.png';

const Auth = (props) => {
	const ref = useRef(null);
	// const history = useHistory();
	const { isAuthenticated } = useSession();
	const { doSignInWithPhoneNumber, RecaptchaVerifier } = useFirebase();

	const [errorMessage, setErrorMessage] = useState('');
	const [phone, setPhone] = useState('');
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [codeSent, setCodeSent] = useState(false);

	const validNumber = validatePhone(phone);

	useEffect(() => {
		if (!ref.current) return;
		window.recaptchaVerifier = new RecaptchaVerifier(ref.current, {
			size: 'invisible',
			'expired-callback': function () {
				console.log('captcha expired');
			},
		});
	});

	if (isAuthenticated) {
		return <Redirect to={{ pathname: props.priorAuthRedirect }} />;
	}

	const submitPhoneNumber = async () => {
		if (!validNumber) {
			setErrorMessage(
				'The phone number you provided was invalid. It must follow the format 555-555-5555'
			);
			setOpen(true);
			return;
		}
		const appVerifier = window.recaptchaVerifier;
		setLoading(true);

		window.confirmationResult = await doSignInWithPhoneNumber(phone, appVerifier);

		setCodeSent(true);
		setLoading(false);
	};

	const tryVerify = (textedCode) => {
		setLoading(true);
		window.confirmationResult
			.confirm(textedCode)
			.then(function (result) {})
			.catch(function (error) {
				setLoading(false);
				console.log({
					message: 'User couldnt sign in',
					error,
				});
			});
	};

	const handleClose = () => setOpen(false);

	return (
		<>
			<Container>
				<Box mt={4}>
					<Grid container justify="center">
						<Grid item xs={12} md={4} lg={3}>
							<Card>
								<CardContent>
									<Grid container justify="center">
										<Grid item xs={6}>
											<img src={logo} alt="Kozi" width="100%" height="auto" />
										</Grid>
									</Grid>

									{codeSent && loading ? (
										<CircularProgress />
									) : codeSent ? (
										<InputCode tryVerify={tryVerify} />
									) : (
										<InputPhone
											setPhone={setPhone}
											buttonRef={ref}
											onClick={submitPhoneNumber}
										/>
									)}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Box>
			</Container>

			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert elevation={6} variant="filled" severity="warning">
					{errorMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

export default Auth;
