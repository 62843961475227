import React from 'react';
import ReactDOM from 'react-dom';
import log from 'loglevel';
import * as serviceWorker from './serviceWorker';

import App from './App';
import { FirebaseProvider } from './components/Firebase';
import { UserSessionProvider } from './components/auth/Session';

import './style/index.css';
import 'fontsource-roboto';

ReactDOM.render(
	<React.StrictMode>
		<FirebaseProvider>
			<UserSessionProvider>
				<App />
			</UserSessionProvider>
		</FirebaseProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// set default log level
log.setLevel('trace');

console.log(process.env);
