import React, { useState } from 'react';
import { Card, CardContent, Grid, Container, Box, Button, Typography, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import cc from 'clipboard-copy';
import { useHistory } from 'react-router-dom';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CardPage = (props) => {
	const history = useHistory();
	const [open, setOpen] = useState(false);

	const copyLink = () => {
		cc(process.env.REACT_APP_CLAIMANT_URL ?? 'https://demo.koziale.com/claimant');
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);

		history.push('/claims');
	};

	return (
		<Container>
			<Box mt={4}>
				<Grid container justify="center">
					<Grid item xs={12} md={8} lg={6}>
						<Card>
							<CardContent>
								<Grid>
									<Grid item>
										<Typography variant="h4" gutterBottom>
											Notify Customer
										</Typography>
									</Grid>
									<Box my={4}>
										<Grid item container spacing={4} justify="center">
											<Grid item>
												<Button variant="contained" color="primary">
													Send Text Message
												</Button>
											</Grid>
											<Grid item>
												<Button variant="contained" color="primary">
													Send Email Message
												</Button>
											</Grid>
											<Grid item>
												<Button
													variant="contained"
													color="primary"
													onClick={copyLink}
												>
													Copy Claim Link
												</Button>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
			<Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					Copied!
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default CardPage;
