import { Model as BaseModel } from 'vue-api-query';
import axios from 'axios';
import firebase from 'firebase/app';

BaseModel.$http = axios;

export default class Model extends BaseModel {
	// define a base url for a REST API
	baseURL() {
		return process.env.REACT_APP_API_URL;
	}

	// implement a default request method
	async request(config) {
		const fu = firebase.auth().currentUser;

		if (fu) {
			const token = await fu.getIdToken();
			config.headers = {
				Authorization: `Bearer ${token}`,
			};
		}

		console.log('request config', { config });

		return this.$http.request(config);
	}
}
