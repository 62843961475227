import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Box } from '@material-ui/core';

const InputPhone = ({ setPhone, buttonRef, onClick }) => (
	<Grid item xs={12}>
		<Typography variant="h5">Welcome</Typography>
		<Typography variant="subtitle1">We'll text you a verification code.</Typography>
		<Box mt={3}>
			<MuiPhoneNumber
				defaultCountry={'us'}
				onlyCountries={['us']}
				onChange={(value) => setPhone(value)}
			/>
			&nbsp;
			<Button ref={buttonRef} onClick={onClick}>
				Next
			</Button>
		</Box>
	</Grid>
);

export default InputPhone;
