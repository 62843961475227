import React, { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	Card,
	CardContent,
	makeStyles,
	Button,
	Paper,
	Table,
	Input,
	FormControl,
	TableHead,
	TableBody,
	withStyles,
	TableCell,
	TableRow,
	TableContainer,
	Typography,
	CircularProgress,
	// InputLabel,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CREATE_CLAIM } from '../util/Routes';
import Claim from '../models/Claim';

const StyledTableCell = withStyles((theme) => ({
	head: {
		// backgroundColor: theme.palette.common.black,
		// color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	filter: {
		marginRight: '15px',
	},
});

const claimFilter = (c, f) => {
	if (!f) return true;

	f = String(f).toLowerCase();
	const name = `${c.customer_given_name} ${c.customer_family_name}`;

	if (String(name).toLowerCase().indexOf(f) !== -1) return true;
	if (String(c.number).toLowerCase().indexOf(f) !== -1) return true;
	if (String(c.email).toLowerCase().indexOf(f) !== -1) return true;
	if (String(c.phone).toLowerCase().indexOf(f) !== -1) return true;

	return false;
};

export default function ClaimsPage() {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [claims, setClaims] = useState([]);
	const [rows, setRows] = useState([]);
	const [fetched, setFetched] = useState(false);
	const [input, setInput] = useState({});

	useEffect(() => {
		if (fetched) return;

		const req = async () => {
			const c = await Claim.get();
			setClaims(c);
			setLoading(false);
		};

		req();
		setFetched(true);
	}, [fetched]);

	useEffect(() => {
		setRows(
			claims
				.filter((c) => claimFilter(c, input.filter))
				.map((claim) => {
					return {
						id: claim.id,
						number: claim.number,
						name: `${claim.customer_given_name} ${claim.customer_family_name}`,
						email: claim.customer_email,
						phone: claim.customer_phone,
						amount: claim.ale_limit + claim.allowance,
						status: 'pending',
					};
				})
		);
	}, [claims, input]);

	const onChange = (e) => {
		const name = e.target.name;

		let i = { ...input };
		i[name] = e.target.value;

		setInput(i);
	};

	return (
		<Box mt={2}>
			<Grid container justify="center">
				<Grid item xs={12} md={8} lg={5}>
					<Card>
						<CardContent>
							<Box mb={0} display="flex" justifyContent="space-between" alignItems="center">
								<Typography variant="h5">Claims</Typography>
								<Box>
									<FormControl margin="none">
										<Input
											id="filter"
											aria-describedby="filter-text"
											placeholder="Search Claims..."
											type="text"
											name="filter"
											autoComplete="none"
											value={input.filter}
											onChange={onChange}
											className={classes.filter}
										/>
									</FormControl>
									<Link
										to={{
											pathname: CREATE_CLAIM,
										}}
									>
										<Button variant="contained" color="primary">
											New Claim
										</Button>
									</Link>
								</Box>
							</Box>
						</CardContent>
					</Card>

					<Box mt={2}>
						{loading ? (
							<CircularProgress />
						) : (
							<TableContainer component={Paper}>
								<Table className={classes.table} aria-label="customized table">
									<TableHead>
										<TableRow>
											<StyledTableCell>Claim</StyledTableCell>
											<StyledTableCell align="right">Name</StyledTableCell>
											<StyledTableCell align="right">Status</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{rows.map((row) => (
											<StyledTableRow
												component={Link}
												to={`/claims/${row.id}`}
												key={row.id}
											>
												<StyledTableCell component="th" scope="row">
													{/* <StyledTableCell align="right"> */}
													{row.number}
												</StyledTableCell>
												<StyledTableCell align="right">{row.name}</StyledTableCell>
												<StyledTableCell align="right">{row.status}</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
