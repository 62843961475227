import React from 'react';
import { useFirebase, useFirebaseAuthChange } from '../../Firebase';
import SessionContext from './useSession';
import log from 'loglevel';

const UserSessionProvider = (props) => {
	const firebase = useFirebase();
	const user = firebase.auth.currentUser;

	const [state, setState] = React.useState({ isAuthenticated: !!user, user, booted: false });

	const onAuthChange = React.useCallback((user) => {
		log.debug('auth status change', { isAuthenticated: !!user, user, booted: true });

		setState({ isAuthenticated: !!user, user, booted: true });
	}, []);

	useFirebaseAuthChange(onAuthChange);

	return <SessionContext.Provider value={state}>{props.children}</SessionContext.Provider>;
};

export default UserSessionProvider;
