import React, { useMemo } from 'react';
// import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { teal, blue } from '@material-ui/core/colors';

import * as ROUTES from './util/Routes';
import { ProtectedRoute } from './util/Router';

import LoginPage from './pages/Login';
import LogoutPage from './pages/Logout';

import ClaimsPage from './pages/Claims';
import CreateClaimPage from './pages/Claims/Create';
import ClaimDetailPage from './pages/Claims/Detail';
import ClaimNotifyPage from './pages/Claims/Notify';

import PaymentsPage from './pages/Payments';
import SendPaymentPage from './pages/Payments/Send';

import { useSession } from './components/auth/Session';
import AppBar from './components/AppBar';

import PaymentAuthPage from './pages/Payment/Auth';
import PaymentDebitPage from './pages/Payment/Debit';

import ClaimantAuthPage from './pages/Claimant/Auth';
import ClaimantCardPage from './pages/Claimant/Card';
import ClaimantClaimsPage from './pages/Claimant/Claims';
import ClaimantClaimPage from './pages/Claimant/Claim';

function App() {
	const { booted, isAuthenticated } = useSession();

	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	console.log('Prefers Dark Mode: ' + prefersDarkMode);

	const theme = useMemo(
		() =>
			createMuiTheme({
				palette: {
					// type: prefersDarkMode ? 'dark' : 'light',
					type: 'light',
					primary: {
						// main: orange[500],
						main: blue[700],
					},
					secondary: {
						main: teal[500],
					},
				},
			}),
		[]
	);

	if (!booted) {
		return null;
	}

	return (
		<ThemeProvider theme={theme}>
			<div>
				<CssBaseline />
				<Router>
					<AppBar />
					<Switch>
						<Route exact path={ROUTES.BASE}>
							<Redirect to={{ pathname: ROUTES.LOGIN }} />
						</Route>
						<Route exact path={ROUTES.HELLO}>
							<Redirect to={ROUTES.LOGIN} />
						</Route>
						<Route exact path={ROUTES.LOGIN} component={LoginPage} />
						<Route exact path={ROUTES.LOGOUT} component={LogoutPage} />
						<ProtectedRoute exact path={ROUTES.CLAIMS} component={ClaimsPage} />
						<ProtectedRoute exact path={ROUTES.CREATE_CLAIM} component={CreateClaimPage} />
						<ProtectedRoute exact path={ROUTES.CLAIM_NOTIFY} component={ClaimNotifyPage} />
						<ProtectedRoute exact path={ROUTES.CLAIM_DETAIL} component={ClaimDetailPage} />

						<ProtectedRoute exact path={ROUTES.PAYMENTS} component={PaymentsPage} />
						<ProtectedRoute exact path={ROUTES.PAYMENTS_SEND} component={SendPaymentPage} />

						<Route
							exact
							path={ROUTES.PAYMENT}
							component={isAuthenticated ? PaymentDebitPage : PaymentAuthPage}
						/>

						{/* claimant */}
						<Route
							exact
							path={ROUTES.CLAIMANT}
							component={isAuthenticated ? ClaimantClaimsPage : ClaimantAuthPage}
						/>
						<ProtectedRoute exact path={ROUTES.CLAIMANT_CLAIM} component={ClaimantClaimPage} />
						<ProtectedRoute exact path={ROUTES.CLAIMANT_CARD} component={ClaimantCardPage} />
					</Switch>
				</Router>
			</div>
		</ThemeProvider>
	);
}

export default App;
