export const BASE = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const HELLO = '/hello';

export const CLAIMS = '/claims';
export const CREATE_CLAIM = '/claims/create';
export const CLAIM_DETAIL = '/claims/:id';
export const CLAIM_NOTIFY = '/claims/:id/notify';

export const CLAIMANT = '/claimant';
export const CLAIMANT_CLAIM = '/claimant/:id';
export const CLAIMANT_CARD = '/claimant/card';

export const PAYMENTS = '/payments';
export const PAYMENTS_SEND = '/payments/send';

export const PAYMENT = '/payment';
export const PAYMENT_DEBIT = '/payment/debit';
