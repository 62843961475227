import React, { useState } from 'react';
import {
	Card,
	CardContent,
	// makeStyles,
	Grid,
	Container,
	Box,
	CircularProgress,
	Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import logo from '../../images/logo-madsky.png';
// import cc from 'clipboard-copy';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// const useStyles = makeStyles((theme) => ({}));

const CardPage = () => {
	// const classes = useStyles();
	const [loading] = useState(true);
	const [open, setOpen] = useState(false);

	// const copyLink = () => {
	// 	cc(process.env.REACT_APP_CLAIMANT_URL ?? 'https://demo.koziale.com/claimant');
	// 	setOpen(true);
	// };

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<Container>
			<Box mt={4}>
				<Grid container justify="center">
					<Grid item xs={12} md={4} lg={3}>
						<Card>
							<CardContent>
								<Grid container justify="center">
									<Grid item xs={6}>
										<img src={logo} alt="Kozi" width="100%" height="auto" />
									</Grid>
								</Grid>

								{loading && (
									<Box display="flex" justifyContent="center" mt={4}>
										<CircularProgress />
									</Box>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
			<Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="success">
					Copied!
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default CardPage;
