import React, { useState, useEffect } from 'react';
import {
	Card,
	CardContent,
	// makeStyles,
	Grid,
	Container,
	Box,
	CircularProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
// import InboxIcon from '@material-ui/icons/Inbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import logo from '../../images/logo-madsky.png';
import Claim from '../../models/Claim';

// const useStyles = makeStyles((theme) => ({}));

const ListItemLink = (props) => {
	return <ListItem button component={Link} {...props} />;
};

const CardPage = () => {
	// const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [fetched, setFetched] = useState(false);
	const [claims, setClaims] = useState([]);

	useEffect(() => {
		if (fetched) return;

		const req = async () => {
			const c = await Claim.get();
			setClaims(c);
			setLoading(false);
		};

		req();
		setFetched(true);
	}, [fetched]);

	return (
		<Container>
			<Box mt={4}>
				<Grid container justify="center">
					<Grid item xs={12} md={6} lg={5}>
						<Card>
							<CardContent>
								<Grid container justify="center">
									<Grid item>
										<img src={logo} alt="Kozi" width="100" height="auto" />
									</Grid>
								</Grid>

								{loading ? (
									<Box display="flex" justifyContent="center" mt={4}>
										<CircularProgress />
									</Box>
								) : (
									<List component="nav">
										{claims.map((claim) => (
											<ListItemLink button to={`/claimant/${claim.id}`} key={claim.id}>
												<ListItemText primary={`Claim ${claim.number}`} />
												<ListItemIcon>
													<ChevronRightIcon />
												</ListItemIcon>
											</ListItemLink>
										))}
									</List>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};

export default CardPage;
