import React, { useState } from 'react';

import {
	Grid,
	Container,
	Button,
	Typography,
	FormControl,
	InputLabel,
	Input,
	Box,
	CircularProgress,
	Card,
	CardContent,
	CardActions,
	Link as muiLink,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { LOGOUT } from '../../util/Routes';

export default function PaymentDebitPage() {
	const [input, setInput] = useState({});
	const [loading, setLoading] = useState(false);

	const onChange = (e) => {
		const name = e.target.name;

		let i = { ...input };
		i[name] = e.target.value;

		setInput(i);
		console.log(i);
	};

	const onSubmit = async () => {
		setLoading(true);
	};

	return (
		<Container>
			<Box mt={2}>
				<Grid container justify="center">
					<Grid item xs={12} md={6} lg={5}>
						<Card>
							<CardContent>
								<Typography variant="h5">Recieve Payment</Typography>

								<Grid container spacing={3}>
									<Grid item xs={12} md={8}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="number">Debit Card Number</InputLabel>
											<Input
												id="number"
												aria-describedby="number-text"
												placeholder="Debit Card Number"
												type="text"
												name="number"
												autoComplete="on"
												value={input.number}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={4}>
										<FormControl fullWidth={true} margin="normal">
											<InputLabel htmlFor="cvc">CVC</InputLabel>
											<Input
												id="cvc"
												aria-describedby="cvc-text"
												placeholder="CVC"
												type="text"
												name="cvc"
												autoComplete="on"
												value={input.cvc}
												onChange={onChange}
											/>
										</FormControl>
									</Grid>
								</Grid>
								{loading && <CircularProgress />}
							</CardContent>
							<CardActions>
								<Button size="small" color="primary" variant="contained" onClick={onSubmit}>
									Submit
								</Button>
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Box>
			<Box mt={2}>
				<Grid container justify="center">
					<Grid item>
						<Link
							component={muiLink}
							to={{
								pathname: LOGOUT,
							}}
						>
							Logout
						</Link>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
}
